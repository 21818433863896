import { createStore } from 'vuex'

const store = createStore({
  state: {
    authString: JSON.parse(localStorage.getItem('authString')) || [] // Initialize with the data from local storage if available
  },
  mutations: {
    addAuthString: function(state, payload) {
      state.authString.push(payload);
      localStorage.setItem('authString', JSON.stringify(state.authString)); // Save to local storage after mutation
    }
  },
  getters: {
    getAuthString: function(state) {
      return state.authString;
    },
    removeAuthString: function(state) {
      state.authString = [];
      localStorage.removeItem('authString'); // Clear from local storage after mutation
    }
  },
})

  export default store